const { getValueOrDefault } = require('@k_frontend/core')

const { formatProductFriendlyName } = require('../utils/friendlyName')
const { formatStampsType } = require('utils/formatStampsType')

function getProductStamp(stamps, stampName) {
  const findedStamp = stamps?.find((selo) => selo.nome === stampName)

  if (findedStamp) {
    return {
      id: findedStamp.codigo,
      name: findedStamp.nome,
      title: findedStamp.titulo,
      backgroundColor: findedStamp.cor_fundo,
      fontColor: findedStamp.cor_fonte,
      type: formatStampsType(findedStamp.tipo)
    }
  }

  return null
}

const self = {}

self.offersListingProductsToCatalog = (products) => {
  const getOfferIsPrime = (product) => {
    return (
      Number(product.primePriceWithDiscount) > 0 &&
      Number(product.primePriceWithDiscount) < Number(product.priceWithDiscount)
    )
  }
  return (
    products?.map((product) => ({
      available: product.available,
      code: product.code,
      name: product.name,
      friendlyName: formatProductFriendlyName(product.name),
      manufacturer: { name: product.manufacturer },
      image: product.image,
      offerDiscount: product.offerDiscount,
      quantity: product.quantity,
      rating: product.rating,
      ratingCount: product.ratingCount,
      primePrice: product.primePrice,
      oldPrice: product.oldPrice,
      oldPrimePrice: product.oldPrimePrice,
      maxInstallment: product?.maxInstallment,
      sellerName: product.seller_name || 'KaBuM!',
      sellerId: product.seller_id || 0,
      category: product.category,
      price: product.priceWithDiscount || 0,
      flags: {
        isFlash: product?.flags?.isFlash,
        isOpenbox: product.flags.openbox,
        isPrime: getOfferIsPrime(product),
        isOffer: true,
        isPreOrder: getValueOrDefault(product?.flags?.isPreOrder, false),
        isHighlight: getValueOrDefault(product.isHighlight, false),
        isFreeShipping: getValueOrDefault(product?.flags?.freeShipping, false)
      },
      offer: {
        name: '',
        code: product.offerCode,
        endsAt: product.offerDates.endDate,
        startsAt: product.offerDates.startDate,
        discountPercentage: product.offerDiscount,
        quantityAvailable: product.quantity,
        hashCode: product.hashCode,
        priceWithDiscount: product.priceWithDiscount,
        mainColor: product?.colors?.main,
        textColor: product?.colors?.text
      },
      prime: getOfferIsPrime(product) && {
        price: product.primePrice,
        priceWithDiscount: product.primePriceWithDiscount,
        discountPercentage:
          product.offerDiscountPrime > 0
            ? product.offerDiscountPrime
            : product.offerDiscount,
        maxInstallmentPrime: product?.maxInstallmentPrime
      },
      tagCode: product.tagCode || 0,
      offerIdMarketplace: product.offerIdMarketplace,
      stamps: product.stamp
    })) || []
  )
}

self.offersHomeProductsToCatalog = (products, offer) => {
  const getOfferIsPrime = (product) => {
    return (
      Number(product.primePriceWithDiscount) > 0 &&
      Number(product.primePriceWithDiscount) < Number(product.priceWithDiscount)
    )
  }
  return (
    products?.map((product) => ({
      available: product.available,
      code: product.code,
      name: product.name,
      friendlyName: formatProductFriendlyName(product.name),
      manufacturer: { name: product.manufacturer },
      image: product.image,
      images: [product.image],
      offerDiscount: product.offerDiscount,
      offerIdMarketplace: product.offerIdMarketplace,
      quantity: product.quantity,
      rating: product.rating,
      ratingCount: product.ratingCount,
      primePrice: product.primePrice,
      oldPrice: product.oldPrice,
      oldPrimePrice: product.oldPrimePrice,
      maxInstallment: product?.maxInstallment,
      sellerName: product.sellerName || 'KaBuM!',
      sellerId: product.sellerId || 0,
      category: product.menu || '',
      price: product.priceWithDiscount || 0,
      priceWithDiscount: product.priceWithDiscount || 0,
      stamps: product.stamp,
      list: `oferta-${offer.path}`,
      flags: {
        isFlash: false,
        isOpenbox: product.flags.openbox,
        isFreeShipping: getValueOrDefault(product.flags?.freeShipping, false),
        isFreeShippingPrime: getValueOrDefault(
          product.flags?.freeShippingPrime,
          false
        ),
        isPrime: getOfferIsPrime(product),
        isOffer: true,
        isPreOrder: getValueOrDefault(product?.flags?.isPreOrder, false),
        isHighlight: getValueOrDefault(product.isHighlight, false)
      },
      offer: offer && {
        name: offer.name,
        endsAt: product.offerDates.endDate,
        startsAt: product.offerDates.startDate,
        discountPercentage: product.offerDiscount,
        quantityAvailable: product.quantity,
        hashCode: product.hash_code || product.hashCode,
        priceWithDiscount: product.priceWithDiscount,
        mainColor: product?.colors?.main,
        textColor: product?.colors?.text
      },
      prime: getOfferIsPrime(product) && {
        price: product.primePrice,
        priceWithDiscount: product.primePriceWithDiscount,
        discountPercentage: product.offerDiscount,
        maxInstallmentPrime: product?.maxInstallmentPrime
      },
      tagCode: product.tagCode || 0
    })) || []
  )
}

self.offerProductsResponse = (products, eventInfo, stamps) =>
  products.map((product) => ({
    code: product.codigo,
    name: product.nome,
    list: `oferta-${eventInfo.path_json}`,
    friendlyName: formatProductFriendlyName(product.nome),
    sellerName: product.seller_name || 'KaBuM!',
    available: product.disponibilidade || false,
    sellerId: product.seller_id || 0,
    menu: product.menu || '',
    category: product.categoria || '',
    manufacturer: product.fabricante.nome || '',
    image: product.foto,
    price: product.valor_origem.normal || 0,
    oldPrice: Number(product.preco_antigo) || 0,
    primePrice: product.preco_prime || 0,
    primePriceWithDiscount: product.preco_desconto_prime || 0,
    oldPrimePrice: product.preco_antigo_prime || 0,
    priceWithDiscount: product.valor_origem.oferta || 0,
    discountPercentage: product.desconto || 0,
    offerDiscount: product.oferta?.oferta_desconto || 0,
    offerIdMarketplace: Number(product.id_marketplace) || 0,
    maxInstallment: product?.max_parcela || '',
    maxInstallmentPrime: product?.max_parcela_prime || '',
    quantity: product.quantidades.disponivel,
    rating: product.classificacao.nota,
    ratingCount: product.classificacao.total,
    stamp: getProductStamp(stamps, product.stamp),
    flags: {
      openbox: product.flags?.openbox || false,
      freeShipping: product.flags?.frete_gratis || false,
      freeShippingPrime: product.flags?.frete_gratis_prime || false,
      isPreOrder: getValueOrDefault(product.flags?.is_pre_order, false)
    },
    hashCode: product.hash_code || product.hashCode,
    offerDates: {
      startDate: product.datas?.inicio || '',
      endDate: product.datas?.final || ''
    },
    colors: {
      main: eventInfo.cor?.primaria || '',
      text: eventInfo.cor?.letra || ''
    },
    isHighlight: getValueOrDefault(product.featured_product, false)
  }))

self.offerInfoResponse = (eventInfo) => ({
  name: eventInfo.nome || '',
  path: eventInfo.path_json || '',
  mainColor: eventInfo.cor.primaria || '#FF6500',
  textColor: eventInfo.cor.letra || '#FFFFFF',
  startDate: eventInfo.data_inicio || '',
  endDate: eventInfo.data_fim || ''
})

self.offerErrorResponse = () => ({
  info: {
    name: '',
    path: '',
    mainColor: '',
    textColor: '',
    startDate: '',
    endDate: ''
  },
  products: []
})

self.offerBannerResponse = (data) => ({
  id: data.banner.id,
  image: data.banner.imagem,
  description: data.descricao,
  offerDescriptionImage: data.banner.banner_descricao
})

self.offerBannerErrorResponse = () => ({
  id: 0,
  image: '',
  description: '',
  offerDescriptionImage: ''
})

module.exports = self
