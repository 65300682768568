import dynamic from 'next/dynamic'

import { Col } from '@k_frontend/ui'

import * as S from './styles'

const OfferTimer = dynamic(() => import('components/OfferTimer'), {
  ssr: false
})

export default function OffersHeader({ offerInfo = {} }) {
  const { name, path, textColor } = offerInfo

  const timerConfig = {
    svg: {
      height: '24px',
      marginRight: '8px'
    },
    text: {
      size: '24px',
      width: '154px',
      textColor
    }
  }
  return (
    <a href={`/ofertas/${path}`}>
      <S.Container>
        <S.OfferTitle
          as='h2'
          sm={2}
          md={3}
          lg={6}
          xl={6}
          textColor={textColor}
          id='active-campaign-1'
        >
          {name}
        </S.OfferTitle>
        {path !== 'ofertadodia' && (
          <Col sm={2} md={3} lg={6} xl={6} justify='flex-end' align='center'>
            <S.CountdownLabel textColor={textColor}>
              TERMINA EM:
            </S.CountdownLabel>
            <OfferTimer config={timerConfig} offerInfo={offerInfo} />
          </Col>
        )}
      </S.Container>
    </a>
  )
}
