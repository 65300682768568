import { useEffect, useRef, useState } from 'react'

import {
  AdModel,
  dataLayerEventPromotionClick,
  dataLayerEventPromotionView,
  formatBannerData,
  sendBeaconData,
  useUser
} from '@k_frontend/core'
import { SrOnly } from '@k_frontend/ui'
import { useBannerMonetizaSplashP } from 'hooks/useBannerMonetiza'
import { useVisibility } from 'hooks/useVisibility'

import * as S from './UpperBanner.styles'

export function UpperBanners() {
  const { session, clientId } = useUser()
  const upperBannerRef = useRef()
  const isVisible = useVisibility(upperBannerRef, { threshold: 0.5 })
  const [wasPrinted, setWasPrinted] = useState(false)
  const { data: bannerData, isFetched } = useBannerMonetizaSplashP()

  const banner = bannerData?.splash_p

  const beaconData = {
    user_id: clientId,
    session_id: session
  }

  const handleBannerClick = (banner: AdModel, index: number) => {
    const payloadFormatted = formatBannerData(
      banner?.title || banner?.campaignName,
      index + 1,
      `tema_splash_p_${index + 1}`
    )
    dataLayerEventPromotionClick(payloadFormatted)
    if (banner?.clickUrl) {
      sendBeaconData(banner?.clickUrl, beaconData)
    }
  }

  useEffect(() => {
    if (isFetched && banner) {
      banner.forEach((banner, index) => {
        const payloadFormatted = formatBannerData(
          banner?.title || banner?.campaignName,
          index + 1,
          `tema_splash_p_${index + 1}`
        )

        if (!wasPrinted) {
          dataLayerEventPromotionView(payloadFormatted)
          sendBeaconData(banner?.impressionUrl, beaconData)
        }
        if (isVisible && !wasPrinted) {
          sendBeaconData(banner?.viewUrl, beaconData)
          setWasPrinted(true)
        }
      })
    }
  }, [isFetched, banner, isVisible, wasPrinted])

  return (
    <S.BlocoBannerSuperior
      id='blocoBannerSuperior'
      ref={upperBannerRef}
      as='section'
      aria-label='Banners de Promoção (superior)'
    >
      <SrOnly as='h2'>Banners de Promoção (superior)</SrOnly>
      {banner?.map((banner, index) => (
        <S.BannerCol
          md={3}
          lg={6}
          xl={6}
          as='a'
          className='bannerSuperior'
          onClick={() => handleBannerClick(banner, index)}
          href={banner?.destinationUrl}
          key={index}
          justify='center'
          bannerQuantity={bannerData?.splash_p?.length}
        >
          {banner?.mediaUrl && (
            <img
              src={banner?.mediaUrl}
              alt={banner?.alt}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          )}
        </S.BannerCol>
      ))}
    </S.BlocoBannerSuperior>
  )
}
