import { Col, Row } from '@k_frontend/ui'
import styled from 'styled-components'

export const BlocoBannerSuperior = styled(Row)`
  margin: ${({ theme }) => theme.spacing.sm} 0px;
  span {
    border-radius: ${({ theme }) => theme.borderRadius.md};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`

export const BannerCol = styled(Col)<{ bannerQuantity: number }>``
