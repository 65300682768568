import { Container as ContainerLib } from '@k_frontend/ui'
import styled from 'styled-components'

export const Wrapper = styled.main<any>`
  display: flex;
  justify-content: center;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#162E5B'};
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 100vh;
`

export const Container = styled(ContainerLib)`
  * {
    min-width: 0;
  }
  background-color: ${({ theme }) => theme.colors.black(300)} !important;
  padding-top: ${({ theme }) => theme.spacing.md};
  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    .doublePadding {
      padding: 0 ${({ theme }) => theme.rem(40)};
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktopLarge}) {
    .doublePadding {
      padding: 0 ${({ theme }) => theme.rem(48)};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    padding-top: ${({ theme }) => theme.spacing.lg};
  }
`

export const GridGeral = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: ${({ theme }) => theme.rem(1568)};
  align-items: center;
  overflow-x: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktopLarge}) {
    margin: 0 ${({ theme }) => theme.spacing.sm};
  }
`

export const ContainerRecommendations = styled(Container)`
  gap: ${({ theme }) => theme.spacing.md};
  padding-top: ${({ theme }) => theme.spacing.sm};

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    gap: ${({ theme }) => theme.spacing.lg};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    padding-top: ${({ theme }) => theme.spacing.lg};
    gap: ${({ theme }) => theme.spacing.xl};
  }
`

export const OfferCampaignSession = styled.section`
  width: 100%;
`
