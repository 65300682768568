import { useEffect, useRef } from 'react'

import { dataLayerEventPromotionView } from '@k_frontend/core'
import BannerSlider from 'components/BannerSlider'
import { formatBanners } from 'models/bannerToDataLayer'

import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import { MainBannerProps } from './MainBanner.types'

export default function MainBanner({
  mainBanner,
  duration,
  cookieIsMobile,
  dataLayerBannerClick
}: MainBannerProps) {
  const INITIAL_SLIDE = 0
  const bannersPrintedOnDatalayer = useRef<number[]>([INITIAL_SLIDE])

  function handleChange(current: number) {
    pushBannerToDatalayer(current)
    changeBackground(current)
  }

  function handleClick(current: number) {
    dataLayerBannerClick(mainBanner?.[current])
  }

  function pushBannerToDatalayer(current: number) {
    const alreadyPrinted = bannersPrintedOnDatalayer.current.includes(current)
    if (!alreadyPrinted) {
      dataLayerEventPromotionView(formatBanners([mainBanner?.[current]]))
      bannersPrintedOnDatalayer.current.push(current)
    }
  }

  function changeBackground(current: number) {
    const backgroundElement = document.getElementsByTagName('main')
    backgroundElement[0].style.backgroundColor =
      mainBanner?.[current]?.background
  }

  useEffect(() => {
    dataLayerEventPromotionView(formatBanners([mainBanner?.[INITIAL_SLIDE]]))
  }, [])

  return (
    <BannerSlider
      banners={mainBanner}
      duration={duration}
      cookieIsMobile={cookieIsMobile}
      onClick={handleClick}
      onChange={handleChange}
    />
  )
}
