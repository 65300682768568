import { Col, Row } from '@k_frontend/ui'
import styled from 'styled-components'

export const Container = styled(Row)`
  padding-top: ${({ theme }) => theme.spacing.sm};
  padding-bottom: ${({ theme }) => theme.spacing.sm};

  @media (max-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  svg {
    width: 14px;
    margin-right: ${({ theme }) => theme.spacing.xxxs};
    @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
      width: 20px;
      margin-right: ${({ theme }) => theme.spacing.xxs};
    }
  }
  .countdownOffer {
    ${(props) => props.theme.font(14, 24, 800, 'uppercase')};
    min-width: 0;

    @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
      ${(props) => props.theme.font(24, 34, 800, 'uppercase')};
    }
  }
`

export const OfferTitle = styled(Col)`
  ${(props) => props.theme.font(14, 24, 800, 'uppercase')};
  color: ${({ textColor }) => textColor};

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    ${(props) => props.theme.font(20, 30, 800, 'uppercase')};
  }
`

export const CountdownLabel = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    ${(props) => props.theme.font(16, 28, 700)};
    color: ${({ textColor }) => textColor};
    display: block;
    margin-right: ${(props) => props.theme.spacing.xxs};
  }
`
